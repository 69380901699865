import Vue from 'vue'
import VueSweetalert2  from 'vue-sweetalert2'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import constants from './plugins/constants'
import './plugins'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(constants)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://globaldesk.com.bo/'

import 'sweetalert2/dist/sweetalert2.min.css'
const options = {
  confirmButtonColor: '#009EE3',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
