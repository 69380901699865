<template>
  <v-text-field
    outlined
    dense
    :label="customLabel"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      slot="append-outer"
      name="append-outer"
    />
  </v-text-field>
</template>

<script>
  export default {
    name: 'BaseTextField',

    props: {
      label: {type: String },
    },

    computed: {
      customLabel() {
        if (this.label != undefined) {
          switch (this.$attrs.required) {
            case undefined:
            case false:
              return this.label;
            case true:
            case "":
              return `*${this.label}`;				
            default:
              return this.label;
          }
        }
        return undefined;
      },
    },
  }
</script>

<style lang="sass">
  //
</style>
