export default {
	name: 'DataTypes',
	
	methods: {
		onlyNumbers($event) {
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
 
			// only allow numbers
			if ((keyCode < 48 || keyCode > 57)) {
			 $event.preventDefault();
			}
		},

		onlyForCurrency($event, price) {
			const priceStr = price != null ? price.toString() : '';
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
			// only allow number and one dot
			if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || priceStr.indexOf('.') != -1)) { // 46 is dot
				$event.preventDefault();
			}
 
			// restrict to 2 decimal places
			if (priceStr != null && priceStr.indexOf(".") > -1 && (priceStr.split('.')[1].length > 1)) {
				$event.preventDefault();
			}
		},

		getNameOfMonth(value, fullname = true) { 
			var name=''
			switch (value) {
				case 1:
					name = fullname ? 'Enero' : 'Ene'
					break;
				case 2:
					name = fullname ? 'Febrero' : 'Feb'
					break;
				case 3:
					name = fullname ? 'Marzo' : 'Mar'
					break;
				case 4:
					name = fullname ? 'Abril' : 'Abr'
					break;
				case 5:
					name = fullname ? 'Mayo' : 'May'
					break;
				case 6:
					name = fullname ? 'Junio' : 'Jun'
					break;
				case 7:
					name = fullname ? 'Julio' : 'Jul'
					break;
				case 8:
					name = fullname ? 'Agosto' : 'Ago'
					break;
				case 9:
					name = fullname ? 'Septiembre' : 'Sep'
					break;
				case 10:
					name = fullname ? 'Octubre' : 'Oct'
					break;
				case 11:
					name = fullname ? 'Noviembre' : 'Nov'
					break;
				case 12:
					name = fullname ? 'Diciembre' : 'Dic'
						break;
				default:
					break;
			}
			return name;
		},

		getNameOfDay(value, fullname = true) { 
			var name=''
			switch (value) {
				case 1:
					name = fullname ? 'Lunes' : 'Lu'
					break;
				case 2:
					name = fullname ? 'Martes' : 'Ma'
					break;
				case 3:
					name = fullname ? 'Miércoles' : 'Mi'
					break;
				case 4:
					name = fullname ? 'Jueves' : 'Ju'
					break;
				case 5:
					name = fullname ? 'Viernes' : 'Vi'
					break;
				case 6:
					name = fullname ? 'Sábado' : 'Sa'
					break;
				case 7:
					name = fullname ? 'Domingo' : 'Do'
					break;
				default:
					break;
			}
			return name;
		},
	},
}