<template>
	<v-select 
		outlined 
		dense
		no-data-text="Datos no disponibles."
		v-bind="$attrs"
    v-on="$listeners"
		:label="customLabel"
		placeholder="Seleccione..."
	>
		<template v-slot:selection="{ item }">
			<slot name="selection" v-bind="{ item }">
				{{ item.text }}
			</slot>
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'BaseSelect',

	props: {
		label: {type: String },
		color: {type: String, default: 'info'}
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				switch (this.$attrs.required) {
					case undefined:
					case false:
						return this.label;
					case true:
					case "":
						return `*${this.label}`;				
					default:
						return this.label;
				}
			}
			return undefined;
		},
	},
}
</script>