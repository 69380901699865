const Paramaters = {
	DOCUMENTS: 1,
	EXTENSIONS: 2,
	COLORS: 3,
	ADULT_CLOTHING_SIZES: 4,
};

const DocumentType = {
	CI: 11
};

const BusinessSectorType = {
	OTHER: 20
};

export default {
	install(Vue, options) {
		Vue.prototype.$Const = {
			PARAMETERS: Paramaters,
			DOCUMENT_TYPE: DocumentType,
			BUSINESS_SECTOR_TYPE: BusinessSectorType
		}
	}
}