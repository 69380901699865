<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/global-desk-logo-dark-v.svg')"
        contain
        max-width="200"
        min-height="78"
        width="100%"
        class="mb-6"
      />

      <base-body>
        Somos una empresa que ofrece servicios tecnológicos con profesionales capacitados en el área de sistemas para el crecimiento de empresas y negocios a nivel nacional e internacional, utilizando para ello metodologías ágiles de diseño y desarrollo de software.
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        :to="{name: 'About'}"
      >
        Más Información
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },
  }
</script>
