import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/home/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Index.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/Index.vue'),
        meta: { src: require('@/assets/about.jpg'), title: 'Quienes Somos' },
      },
      {
        path: '/apps',
        name: 'Apps',
        component: () => import(/* webpackChunkName: "apps" */ '@/views/apps/Index.vue'),
        meta: { src: require('@/assets/product.jpg'), title: 'Aplicaciones' },
      },
      {
        path: '/apps/gavcom',
        name: 'Gavcom',
        component: () => import(/* webpackChunkName: "apps-gavcom" */ '@/views/apps/Gavcom.vue'),
      },
      // {
      //   path: '/people',
      //   name: 'People',
      //   component: () => import(/* webpackChunkName: "people" */ '@/views/people/Index.vue'),
      //   meta: { src: require('@/assets/people3.jpg'), title: 'Personas' },
      // },
      {
        path: '/people/create-account',
        name: 'CreateAccount',
        component: () => import(/* webpackChunkName: "people-create-account" */ '@/views/people/CreateAccount.vue'),
      },
      {
        path: '/contact-us',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact-us" */ '@/views/contact-us/Index.vue'),
        meta: { src: require('@/assets/contact.jpg'), title: 'Contactos' },
      },
      {
        path: '*',
        name: 'FourOhFour',
        component: () => import(/* webpackChunkName: "404" */ '@/views/404/Index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

export default router
