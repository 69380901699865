<template>
	<div align="start">
		<label v-bind="$attrs" v-on="$listeners" 
			class="theme--light v-label v-label--active"
			style="font-size: 13px;"
			:class="classes"
		>
			{{text}}	
		</label>
		<slot name="default"/>
	</div>
</template>

<script>
export default {
	name: 'BaseLabel',
	props: {
		text: { type: String, required: true },
	},

	computed: {
		classes() {
			var result = []
			if (this.$attrs.disabled == "" || this.$attrs.disabled == true) {
				result.push('v-label--is-disabled')
			}
			return result
		}
	},
}
</script>