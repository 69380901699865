<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="MANTENTE EN CONTACTO CON NOSOTROS"
        pace="6"
      >
        Escríbenos con total libertad sobre cualquier duda o consulta que tengas acerca de nuestros servicios y uno de nuestros asesores comerciales se comunicara contigo lo antes posible.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },
  }
</script>
