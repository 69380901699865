<template>
	<base-text-field class="centered-input"
		:class="{'custom-max-width' : !fullWidth}"
		v-bind="$attrs"
    v-on="$listeners"
		v-bind:value="value" v-on:input="$emit('input', $event)"
		:label="customLabel"
		:placeholder="placeholder"
		:prefix="prefix" 
		@keypress="onlyNumbers($event)"
		type="number"
	></base-text-field>
</template>

<script>
// Mixins
import DataTypes from '@/mixins/data-types'

export default {
	name: 'BaseNumberField',
	mixins: [DataTypes],

	model:{
		prop:'value',
		event:'input'
	},

	props: {
		label: {type: String },
		value: {type: [String, Number] },
		prefix: {type: String, default: '' },
		fullWidth: {type: Boolean, default: false},
	},

	data() {
		return {
			placeholder: '0'
		}
	},

	computed: {
		customLabel() {
			if (this.label != undefined) {
				return this.$attrs.required ? `*${this.label}` : this.label;	
			}
			return undefined;
		}
	},
}
</script>