<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-form ref="contactForm" onSubmit="return false;">
      <base-text-field label="Nombre"/>

      <base-text-field label="Teléfono/Celular"/>

      <base-text-field label="Correo Electrónico"/>

      <base-text-field label="Asunto"/>

      <base-textarea
        class="mb-6"
        label="¿Cómo podemos ayudarte?"
      />
    </v-form>

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      href="mailto:info@globaldesk.com.bo"
      outlined
      target="_blank"
    >
      Enviar mensaje
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'ENVÍANOS TU MENSAJE',
      },
    },

    data() {
      return {
        commonRules: {
          required: value => {
            if (value === undefined || value === null) {
              return 'Campo requerido.'
            }
            const length = value.toString().trim().length
            if (length <= 0) {
              return 'Campo requerido.'
            }
            return true
          },
          email: value => {
            if (value) {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'E-mail inválido.'  
            }
            return true;
          }
        },
      }
    },

    methods: {
      // async send(){
      //   let me=this;
      //   me.$swal(
      //         {
      //           position: 'center-center',
      //           icon: 'success',
      //           title: me.swalConfig.successMessage,
      //           showConfirmButton: false,
      //           timer: 2000
      //         }
      //       );
      //     return;
        
      //   if (!me.$refs.contactForm.validate()){
      //     return;
      //   }
      // }
    },
  }
</script>
